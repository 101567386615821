<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content class="cursor-pointer" vs-trigger-click>
    <feather-icon v-if="totalUnreadNotificationCount<=5" icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="unreadNotifications.length" />
    <feather-icon v-else icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="`${unreadNotifications.length}+`" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div v-if="unreadNotifications.length" class="notification-top text-center p-5 bg-primary text-white">
        <h3 v-if="totalUnreadNotificationCount<=5" class="text-white">{{ unreadNotifications.length }} New</h3>
        <h3 v-else class="text-white">{{ unreadNotifications.length }}+ New</h3>
        <p class="opacity-75">App Notifications</p>
      </div>
      <div v-else class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">No New</h3>
        <p class="opacity-75">App Notifications</p>
      </div>

      <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items">
          <li v-for="(ntf,index) in unreadNotifications" :key="ntf.index" :class="(ntf.metaData) ? 'flex justify-between px-4 py-4 notification cursor-pointer' : 'flex justify-between px-4 py-4 notification' ">
            <div class="flex items-start" @click="clickNotification(ntf,index)">
<!--              <feather-icon :icon="ntf.icon" :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>-->
              <feather-icon icon="AlertCircleIcon" :svgClasses="[`text-primary`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
              <div class="mx-2">
                <span class="font-medium block notification-title" :class="[`text-primary`]">{{ ntf.title }}</span>
                <small>{{ ntf.content }}</small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.createdAt) }}</small>
          </li>
        </ul>
      </VuePerfectScrollbar>

      <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer"
        v-if="totalNotificationCount>0"
        @click="viewAllNotifications"
      >
        <span>View All Notifications</span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      unreadNotifications: [],
      totalUnreadNotificationCount : 0,
      totalNotificationCount : 0,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  async mounted(){
    let AppActiveUser = this.$store.state.AppActiveUser

    if(AppActiveUser && AppActiveUser.userType==='superAdmin'){
      await this.getSuperAdminNotifications()
  
    }
  },
  methods: {
    ...mapActions('adminNotifications',['getNotificationsList','seeNotification','viewAllNotification']),
    getSuperAdminNotifications(){
      this.getNotificationsList().then(response => {
        // this.unreadNotifications=response.data.data.unReadNotifications
        // this.totalUnreadNotificationCount=response.data.data.totalUnreadNotificationCount

        this.unreadNotifications=this.$store.state.adminNotifications.superAdminUnreadNotifications
        this.totalUnreadNotificationCount=this.$store.state.adminNotifications.superAdminTotalUnreadNotificationCount
        this.totalNotificationCount=this.$store.state.adminNotifications.superAdminTotalNotificationCount

      }).catch(error=>{})

    },
    clickNotification(data,index){

      if(data.seenAt===null){
        let thisInstance = this
        this.seeNotification(data._id).then( (response) =>{
          
          //remove the record from list
          // this.unreadNotifications.splice(index, 1);
          thisInstance.getSuperAdminNotifications()


        }).catch(error=>{})
      }
      
      if(data.metaData){
        this.$router.replace({path:'/'+data.metaData})
      }

    },
    elapsedTime(startTime) {
      let x        = new Date(startTime)
      let now      = new Date()
      var timeDiff = now - x
      timeDiff    /= 1000

      var seconds = Math.round(timeDiff)
      timeDiff    = Math.floor(timeDiff / 60)

      var minutes = Math.round(timeDiff % 60)
      timeDiff    = Math.floor(timeDiff / 60)

      var hours   = Math.round(timeDiff % 24)
      timeDiff    = Math.floor(timeDiff / 24)

      var days    = Math.round(timeDiff % 365)
      timeDiff    = Math.floor(timeDiff / 365)

      var years   = timeDiff

      if (years > 0) {
        return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
      } else if (days > 0) {
        return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
      } else if (hours > 0) {
        return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago'
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago'
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' sec ago' : 'just now')
      }

      return 'Just Now'
    },
    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      let date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    },
    viewAllNotifications(){
      this.viewAllNotification().then(response => {
        this.unreadNotifications=[]
        this.totalUnreadNotificationCount=0
      }).catch(error=>{})
      this.$router.push({name: 'notification-list'}).catch(() => {})
    }
  }
}

</script>