<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">

    <div class="vx-navbar-wrapper" :class="classObj">

      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />

        <template v-if="appName ==='FlipPay'">
          <div class="site-logo self-center"  @click="homePage()">
          <img :src="flipLogo" alt="Flippay" />
          </div>
        </template>

        <template v-else-if="appName==='RelloPay'">
          <div class="site-logo"  @click="homePage()">
          <img :src="relloLogo" alt="RelloPay" />
          </div>
        </template>

        <vs-spacer />

        <!-- <notification-drop-down /> -->

        <profile-drop-down />

      </vs-navbar>
    </div>
  </div>
</template>


<script>
  import NotificationDropDown from "./components/NotificationDropDown.vue"
  import ProfileDropDown      from "./components/ProfileDropDown.vue"

  export default {
    data(){
      return{
        relloLogo: require('@/assets/images/logo/rellopay-logo.svg'),
        flipLogo: require('@/assets/images/logo/flippay-logo.svg'),
        appName: process.env.VUE_APP_NAME ||"FlipPay"
      }
    },
    name: "the-navbar-vertical",
    props: {
      navbarColor: {
        type: String,
        default: "#fff",
      },
    },
    components: {
      NotificationDropDown,
      ProfileDropDown,
    },
    computed: {
      pageTitle(){
        return this.$route.meta.pageTitle
      },
      navbarColorLocal() {
        return this.$store.state.theme === "dark" && this.navbarColor === "#fff" ? "#10163a" : this.navbarColor
      },
      verticalNavMenuWidth() {
        return this.$store.state.verticalNavMenuWidth
      },
      textColor() {
        return {'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark')}
      },
      windowWidth() {
        return this.$store.state.windowWidth
      },

      // NAVBAR STYLE
      classObj() {
        if (this.verticalNavMenuWidth == "default")      return "navbar-default"
        else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced"
        else if (this.verticalNavMenuWidth)              return "navbar-full"
        else return "navbar-default"
      },
    },
    methods: {
      showSidebar() {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
      },
      homePage(){
        this.$router.push({name:"dashboard"});
      }
    }
  }
</script>

